const TableData = [
        {
            id: 'You need to enable JavaScript to run this app',
            type: 'You need to enable JavaScript to run this app',
            num: '',
            type1: 'You need to enable JavaScript to run this app',
            num1: 'You nee,d to enable JavaScript to run this app'
        },
        {
            id: 'You need to enable JavaScript to run this app',
            type: 'You need to enable JavaScript to run this app',
            num: 'You nee,d to enable JavaScript to run this app',
            type1: 'You need to enable JavaScript to run this app',
            num1: 'You nee,d to enable JavaScript to run this app'
        },
    ]

export default TableData;
