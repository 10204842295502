import { Container, Grid, Input } from "woxin-user-interface";

const InputComp = () => {
    return (
        <Container>
            <Grid lg={6}>
                <Input/>
            </Grid>
        </Container>
    );
}

export default InputComp;