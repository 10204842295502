import { Grid } from 'woxin-user-interface';
import './style.css';
import { FaRegCopy } from "react-icons/fa6";

type Props = {
    data: Array<string>;
}

const DemoCode = (props: Props) => {
    const { data } = props;

    const handleCopy = () => {
        navigator.clipboard.writeText(data.toString());
        alert("Copied successfully");
    }

    return (
        <Grid className='demo-code'>
            <Grid style={{ textAlign: 'right' }}>
                <FaRegCopy onClick={handleCopy} />
            </Grid>
            <Grid>
                <div id='myInput'>
                    {data.map((item) => {
                        return (
                            <Grid style={{ height: "auto", overflow: "auto" }}>
                                <pre style={{width:0}}><code style={{ fontSize: "large" }}>{item}</code></pre>
                            </Grid>
                        )
                    })}
                </div>
            </Grid>
        </Grid >
    )
};

export default DemoCode;