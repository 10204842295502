import { Typo } from "woxin-user-interface";
import TextConstant from './constant';
import DemoCode from "../../service/SampleCodeBox";

const TextComp = () => {
    return (
        <div className="container">

            <div className="sample-background">
                <Typo size="h1">h1.Lorem ipsum</Typo>
                <Typo size="h2">h2.Lorem ipsum is dummy Typo</Typo>
                <Typo size="h3">h3.Lorem ipsum is dummy Typo</Typo>
                <Typo size="h4">h4.Lorem ipsum is dummy Typo</Typo>
                <Typo size="h5">h5.Lorem ipsum is dummy Typo</Typo>
                <Typo size="h6">h6.Lorem ipsum is dummy Typo</Typo>
                <Typo size="highlight">highlight.Lorem Ipsum is simply dummy Typo of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Typo ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typo>
                <Typo size="subtitle">subtitle.Lorem Ipsum is simply dummy Typo of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Typo ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typo>
                <Typo size="body">body.Lorem Ipsum is simply dummy Typo of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Typo ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typo>
                <Typo size="para">para.Lorem Ipsum is simply dummy Typo of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Typo ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typo>
                <Typo size="button">button.Lorem Ipsum</Typo>
            </div>

            <div>
                <DemoCode data={TextConstant.size} />
            </div>
            
        </div>
    );
}

export default TextComp;