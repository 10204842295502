const CheckboxConstants = {
    basic: [`
<Checkbox id="id01" name="" value="" label="" checked={isChecked01} onChange={() => setIsChecked01(!isChecked01)} />
<Checkbox id="id01" name="" value="" label="" checked={isChecked02} onChange={() => setIsChecked02(!isChecked02)} />
<Checkbox id="id01" name="" value="" label="" checked={isChecked03} onChange={() => setIsChecked03(!isChecked03)} disabled />
<Checkbox id="id01" name="" value="" label="" checked={isChecked04} onChange={() => setIsChecked04(!isChecked04)} disabled />
    `]
}


export default CheckboxConstants;