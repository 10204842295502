const GridConstants = {

    basic: [`
    const style = {
        background-color: antiquewhite;
        text-align: center;
        padding: 10px;
        margin: 10px;
    }

    <Grid container className="sample-background">
        <Grid item lg={4} ><Typo style={style}>adadadasd</Typo></Grid>
        <Grid item lg={8} ><Typo style={style}>adadadasd</Typo></Grid>
        <Grid item lg={8} ><Typo style={style}>adadadasd</Typo></Grid>
        <Grid item lg={4} ><Typo style={style}>adadadasd</Typo></Grid>
    </Grid>    
    `],


    tableData: [
        {
            Varient: 'xl',
            Default: '12',
            Values: '1, 2, 3, ... 12',
            Screen: 'Larger than full HD screens',
            Width:  '1920 + px',
        },
        {
            Varient: 'lg',
            Default: '12',
            Values: '1, 2, 3, ... 12',
            Screen: 'For desktop screens',
            Width:  '1041px to 1920px',
        },
        {
            Varient: 'md',
            Default: '12',
            Values: '1, 2, 3, ... 12',
            Screen: 'Landscape view on Tablets',
            Width:  '768px to 1040px',
        },
        {
            Varient: 'sm',
            Default: '12',
            Values: '1, 2, 3, ... 12',
            Screen: 'portrait view on tablets',
            Width:  '481px to 767px',
        },
        {
            Varient: 'xs',
            Default: '12',
            Values: '1, 2, 3, ... 12',
            Screen: 'Mobile view',
            Width:  'upto 480px',
        },
    ]
}

export default GridConstants;