import React from "react";
import { Snap } from "woxin-user-interface";

const ImageComp = () => {
    return (
        <div className="container">
            <Snap location="https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w1200/2023/10/free-images.jpg" />
        </div>
    );
}

export default ImageComp;