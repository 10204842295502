import DemoCode from '../../service/SampleCodeBox';
import './style.css';
import { Container, Grid, Table, Typo, Wrapper } from "woxin-user-interface";
import GridConstants from './constants';

const GridComp = () => {

    return (
        <Container>

            <Wrapper>
                <Grid>
                    <Typo size="h3">Grid</Typo>
                    <Typo>
                        The Woxin Design responsive format matrix adjusts to screen size and direction, guaranteeing consistency across designs.
                    </Typo>
                    <Typo>
                        The matrix makes visual consistency between formats while permitting adaptability across a wide assortment of plans. Woxin Design's responsive UI depends on a 12-section matrix design.
                    </Typo>
                    <Typo>
                        The Network part ought not be mistaken for an information lattice; it is more like a design framework. For an information framework head to the DataGrid part.
                    </Typo>
                </Grid>
            </Wrapper>

            <Wrapper>
                <Typo size="h3">How it works?</Typo>
                <Typo size='h5'>The grid system is attached with the Grid component:</Typo>
                <Typo>It involves CSS's Adaptable Box module for high adaptability.</Typo>
                <Typo>There are two sorts of format: holders and things.</Typo>
                <Typo>Thing widths are set in rates, so they're dependably liquid and estimated comparative with their parent component.</Typo>
                <Typo>Things have cushioning to make the dispersing between individual things.</Typo>
                <Typo>There are five network breakpoints: xs, sm, md, lg, and xl.</Typo>
                <Typo>Whole number qualities can be given to each breakpoint, showing the number of the 12 accessible segments are involved by the part when the viewport width fulfills the breakpoint imperatives.</Typo>
                <Typo>On the off chance that you are new to or new to flexbox, we urge you to peruse this CSS-Stunts flexbox guide.</Typo>
            </Wrapper>

            <Wrapper>
                <Typo size='h4'>Flexible grids</Typo>
                <Typo>Fluid grids use columns that scale and resize content. A fluid grid's layout can use breakpoints to determine if the layout needs to change dramatically.</Typo>
                <Typo>Column widths are integer values between 1 and 12; they apply at any breakpoint and indicate how many columns are occupied by the component.</Typo>
                <Typo>A value passed to any given breakpoint also applies to all wider breakpoints (if they have no values explicitly defined). For example, xs={12} sizes a component to occupy the full width of its parent container, regardless of the viewport size.</Typo>
            </Wrapper>

            <Wrapper>
                <Grid container className="sample-background">
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ padding: 5 }}>
                        <Typo className='style'>4</Typo>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{ padding: 5 }}>
                        <Typo className='style'>8</Typo>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{ padding: 5 }}>
                        <Typo className='style'>8</Typo>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ padding: 5 }}>
                        <Typo className='style'>4</Typo>
                    </Grid>
                </Grid>
                <Grid >
                    <DemoCode data={GridConstants.basic} />
                </Grid>
            </Wrapper>

            <Wrapper>
                <Typo size='h4'>Screens</Typo>
                <Typo>Grids are designed for different screens. We can set each component differently on various screens.</Typo>
            </Wrapper>

            <Wrapper>
                <Table tableData={GridConstants.tableData} />
            </Wrapper>

        </Container >
    );
};

export default GridComp;