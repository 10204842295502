const textConstant = {
    size: [
        `<Text size="h1">h1.Lorem ipsum</Text>`,
        `<Text size="h2">h2.Lorem ipsum ...</Text>`,
        `<Text size="h3">h3.Lorem ipsum ...</Text>`,
        `<Text size="h4">h4.Lorem ipsum ...</Text>`,
        `<Text size="h5">h5.Lorem ipsum ...</Text>`,
        `<Text size="h6">h6.Lorem ipsum ...</Text>`,
        `<Text size="highlight">highlight.Lorem Ipsum ...</Text>`,
        `<Text size="subtitle">subtitle.Lorem Ipsum ...</Text>`,
        `<Text size="body">body.Lorem Ipsum ...</Text>`,
        `<Text size="para">para.Lorem Ipsum ...</Text>`,
        `<Text size="button">button.Lorem Ipsum</Text>`
    ]
}

export default textConstant;