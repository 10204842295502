import { Container, Grid, Search } from "woxin-user-interface";
import { TbNavigationSearch } from "react-icons/tb";


const SearchComp = () => {

    const handleClick = () => {
        alert("hi");
    }

    return (
        <Container>
            <Grid>
                <Search lable="fsnjkl" placeHolder="kjashdjkahdkkl" icon={<TbNavigationSearch />} onClick={handleClick} />
            </Grid>
        </Container>
    );
}

export default SearchComp;