const BtnConstant = {

    variant: [`
    <Grid className="sample-background">
        <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
            <Button caption='DEFAULT' margin={10} />
        </Grid>
        <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
            <Button caption='TEXT' margin={10} variant="text" />
        </Grid>
        <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
            <Button caption='FILL DARK' margin={10} variant="fill-dark" />
        </Grid>
        <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
            <Button caption='FILL LIGHT' margin={10} variant="fill-light" />
        </Grid>
        <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
            <Button caption='STROKES' margin={10} variant="storke" />
        </Grid>
    </Grid>
    `],

    size: [`
    <Button caption='Default' />
    <Button caption='Small' width="small" />
    <Button caption='Medium' width="medium" />
    <Button caption='Large' width="large" />
    <Button caption='Full Width' width="fullWidth" />
    `],

    tableData: [
        {
            Property: 'variant',
            Default: 'fill-dark',
            Values: 'Text, fill-dark, fill-light, stroke'
        },
        {
            Property: 'size',
            Default: 'fit-content',
            Values: 'small, medium, large, fullWidth'
        },
        {
            Property: 'lulu',
            Default: 'fit-content',
            Values: 'small, medium, large, fullWidth'
        },
    ]
}

export default BtnConstant;
