import { Button, Grid, Typo, Snap } from "woxin-user-interface";
import './style.css';
import LandingImage from '../../assets/images/happy-people-landing-page.png';
import { useEffect } from "react";
import UseFetch from "../../service/UseFetch";

const Landingpage01 = () => {

    const handlePageHeight = () => {
        const pageHeight: number = window.screen.height;
        // console.log(pageHeight);
    }
    handlePageHeight();


    useEffect(() => {
        window.addEventListener("resize", handlePageHeight);
        return () => {
            window.removeEventListener("resize", handlePageHeight);
        };
    }, []);

    // const handleFetch = () => {
    //     const res:object = UseFetch(url:'https://jsonplaceholder.typicode.com/todos/1')
    // };

    return (
        <Grid>
            <Grid container className="mt-80">
                <Grid className="mainContentHeight p80 rightHeroContent" xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Snap location={LandingImage} style={{ maxWidth: '130%' }} />
                </Grid>
                <Grid style={{ padding: { xl: 90, lg: 40, md: 20, sm: 40, xs: 20 } }} xl={6} lg={6} md={12} sm={12} xs={12} >
                    <Typo size="h3" style={{ marginTop: '2rem', fontSize: "7rem", fontWeight: 700 }}>Design Interfaces</Typo>
                    <Typo size="h3" style={{ fontSize: "7rem", fontWeight: 200 }}>with Ease</Typo>
                    <Typo size="para" style={{ marginTop: 40, fontSize: "2.5rem", fontWeight: 200 }}>Explore a library of powerful UI components and upcoming hooks to enhance your user interface projects</Typo>
                </Grid>

            </Grid>

            <Grid container style={{ marginTop: 40 }} >

                <Grid xl={8} lg={8} md={12} sm={12} xs={12} style={{ display: 'flex' }}>
                    <Grid container style={{ marginTop: 'auto' }}>
                        <Grid className="p80 bg01" xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Typo size="h5">Component Diversity</Typo>
                            <Typo >A rich set of customizable components tailored for modern web applications.</Typo>
                        </Grid>
                        <Grid className="p80 bg02" xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Typo size="h5">Ease of Use</Typo>
                            <Typo >Simple integration with minimal setup, designed for developers of all skill levels.</Typo>
                        </Grid>
                        <Grid className="p80 bg03" xl={4} lg={4} md={4} sm={12} xs={12} style={{ borderTopRightRadius: 500 }}>
                            <Typo size="h5">Future-Proof Hooks</Typo>
                            <Typo >Upcoming hooks to seamlessly connect your interfaces with advanced functionalities</Typo>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="bg04" xl={4} lg={4} md={12} sm={12} xs={12} style={{ textAlign: "center", borderTopLeftRadius: 500 }}>
                    <Typo size="para" style={{ fontSize: 45, marginTop: 40, color: 'white', fontWeight: 700 }}>Wanna</Typo>
                    <Typo size="para" style={{ fontSize: 54, color: 'white', textAlign: 'center' }}>Jump in?</Typo>
                    <Button width="medium" style={{ marginTop: 20, fontSize: 21, borderRadius: 100 }}>Get Started</Button>
                    <Button width="medium" style={{ marginTop: 20, fontSize: 21, marginBottom: 40, borderRadius: 100, background: 'white', color: '#2196f3', fontWeight: 700 }}>View on npm</Button>
                </Grid>
                {/* <Button onClick={handleFetch}/> */}
            </Grid>
        </Grid>
    );
}

export default Landingpage01;