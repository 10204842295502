const CardConstant = {
    basic: [
        `<Card cardGutter={10} elements={["iconRight", "typo"]} cardBackground="#1E88E5" cardColor="white" iconName={<FaClo... />} widthCard={20} typoContent="Lorem Ipsum..." />`,
        `<Card cardGutter={10} elements={["iconLeft", "typo"]} cardBackground="white" cardBorder={1} cardBorderStyle="dashed" cardColor="#1E88E5" iconName={<FaClo... />} widthCard={20} typoContent="Lorem Ipsum..." />`
    ],
    elements: [
        `<Card cardGutter={10} elements={["iconLeft"]} iconName={<FaClo... />}/>`,
        `<Card cardGutter={10} elements={["snapLeft"]} widthCard={10}  snapLink="http://..."/>`,
        `<Card cardGutter={10} elements={["typo"]} typoContent="Lorem Ipsum..." />`
    ],
    alignment: [
        `<Card cardGutter={10} elements={["iconLeft", "typo"]} widthCard={20} iconName={<FaClo... />} typoContent="Lorem Ipsum..." />`,
        `<Card cardGutter={10} elements={["iconRight", "typo"]} widthCard={20} iconName={<FaClo... />} typoContent="Lorem Ipsum..." />`,
        `<Card cardGutter={10} elements={["snapRight", "typo"]} widthSnap={20} widthCard={20} snapLink="http://..." typoContent="Lorem Ipsum..." />`,
        `<Card cardGutter={10} elements={["snapLeft", "typo"]} widthSnap={20} widthCard={20} snapLink="http://..." typoContent="Lorem Ipsum..." />`,
        `<Card cardGutter={10} elements={["snapRight", "iconLeft"]} widthSnap={60} widthCard={10} iconName={<FaClo... />} snapLink="http://..." />`,
        `<Card cardGutter={10} elements={["snapRight", "iconRight"]} widthSnap={60} widthCard={10} iconName={<FaClo... />} snapLink="http://..." />`
    ],
    tableData: [
        {
            Property: 'elements',
            Type: 'array of string',
            Default: '{[ ]}',
            Values: 'iconLeft, snapLeft, typo, snapRight, iconRight'
        },
        {
            Property: 'cardGutter',
            Type: 'number',
            Default: '',
            Values: '1, 2, 3, ...'
        },
        {
            Property: 'widthCard',
            Type: 'number',
            Default: '30',
            Values: '1, 2, 3, ... will be considered as %'
        },
        {
            Property: 'widthTypo',
            Type: 'number',
            Default: '',
            Values: '1, 2, 3, ... will be considered as %'
        },
        {
            Property: 'widthIcon',
            Type: 'number',
            Default: '',
            Values: '1, 2, 3, ... will be considered as %'
        },
        {
            Property: 'widthSnap',
            Type: 'number',
            Default: '',
            Values: '1, 2, 3, ... will be considered as %'
        },
        {
            Property: 'typoContent',
            Type: 'string',
            Default: '',
            Values: 'can be any string'
        },
        {
            Property: 'iconName',
            Type: 'string',
            Default: '',
            Values: 'can be any string like {<iconName/>}'
        },
        {
            Property: 'iconSize',
            Type: 'number',
            Default: '',
            Values: '1, 2, 3, ... will be considered in px'
        },
        {
            Property: 'cardBackground',
            Type: 'string',
            Default: '',
            Values: 'can be any string like "#fff"'
        },
        {
            Property: 'cardBorderStyle',
            Type: 'string',
            Default: 'solid',
            Values: 'can be any border style. Refer to HTML border styles'
        },
        {
            Property: 'cardColor',
            Type: 'string',
            Default: '',
            Values: 'can be any string like "#fff"'
        },
        {
            Property: 'cardBorder',
            Type: 'number',
            Default: '0',
            Values: '1, 2, 3, ... will be considered in px'
        },
        {
            Property: 'snapLink',
            Type: 'string',
            Default: '',
            Values: 'can be any string like "https://www.google.com/"'
        },
    ]
}
export default CardConstant;