import ProjectRoutes from '../Routes';
import SideNav from './projectNavigation/ProjectNavigation';

const ComponentMaster = () => {

    return (
        <>
            <SideNav />
            <ProjectRoutes />
        </>
    );
}

export default ComponentMaster;
