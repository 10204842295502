import TableData from './constant';
import { Table } from 'woxin-user-interface';

const TableComp = () => {

    return (
        <div className="container">
            <Table tableData={TableData} />
        </div>
    );
}

export default TableComp;