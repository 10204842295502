import { Grid, Sidebar } from "woxin-user-interface";
import Logo from '../../assets/images/woxin-user-interface-logo-white.svg';

const items = [

    {
        link: "/",
        linkName: "Home",
    },
    {
        linkName: "Components",
        submenu: [
            {
                link: "/accordian",
                linkName: "Accordian",
            },
            {
                link: "/button",
                linkName: "Button",
            },
            {
                link: "/card",
                linkName: "Card",
            },
            {
                link: "/image",
                linkName: "Snap",
            },
            {
                link: "/input",
                linkName: "Input",
            },
            {
                link: "/search",
                linkName: "Search",
            },
            {
                link: "/typo",
                linkName: "Typo"
            },
            {
                link: "/table",
                linkName: "Table",
            },
            {
                link: "/upload",
                linkName: "Upload",
            },
            {
                link: "/checkbox",
                linkName: "Checkbox",
            },

        ]
    },
    {
        linkName: "Design",
        submenu: [
            {
                link: "/container",
                linkName: "Container",
            },
            {
                link: "/grid",
                linkName: "Grid",
            },
            {
                link: "/Wrapper",
                linkName: "Wrapper",
            },
        ]
    },
]

const SideNav = () => {

    return (
        <Grid style={{ margin: 20, fontSize: "1.5rem" }} xl={1} lg={1} md={1} sm={1} xs={1} >
            <Sidebar sticky={window.location.pathname==="/"? false: true} logo={Logo} logoWidth="-webkit-fill-available" backgroundColor="#ffffff" menuItem={items} color="black" width={300} target="_self" />
        </Grid>
    );
}

export default SideNav;