import React, { useState } from 'react';
import { CheckboxGroup, Checkbox, Container, Wrapper, Grid, Typo } from 'woxin-user-interface';
import DemoCode from '../../service/SampleCodeBox';
import CheckboxConstants from './constants';


const CheckboxGroupComp = () => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [isChecked01, setIsChecked01] = useState(true);
    const [isChecked02, setIsChecked02] = useState(false);
    const [isChecked03, setIsChecked03] = useState(true);
    const [isChecked04, setIsChecked04] = useState(false);

    const options = [
        { id: 'vehicle11', name: 'vehicle', value: 'Bike', label: 'I have a bike' },
        { id: 'vehicle12', name: 'vehicle', value: 'Car', label: 'I have a car' },
        { id: 'vehicle13', name: 'vehicle', value: 'Boat', label: 'I have a boat' },
    ];

    const handleCheckboxGroupChange = (newSelectedValues: string[]) => {
        setSelectedValues(newSelectedValues);
    };

    return (
        <Container>

            <Wrapper>
                <Grid>
                    <Typo size="h3">Checkbox</Typo>
                    <Typo>
                        Checkboxes can be utilized to turn a choice on or off.
                    </Typo>
                    <Typo>
                        In the event that you have different choices showing up in a rundown, you can protect space by utilizing checkboxes rather than on/off switches. In the event that you have a solitary choice, try not to utilize a checkbox and utilize an on/off switch all things considered.
                    </Typo>
                </Grid>
            </Wrapper>


            <Wrapper>
                <Typo size='h4'>Basic Checkbox</Typo>
                <Typo>Grids are designed for different screens. We can set each component differently on various screens.</Typo>
            </Wrapper>
            <Grid container className="sample-background">
                <Checkbox id="id01" name="" value="" label="" checked={isChecked01} onChange={() => setIsChecked01(!isChecked01)} />
                <Checkbox id="id01" name="" value="" label="" checked={isChecked02} onChange={() => setIsChecked02(!isChecked02)} />
                <Checkbox id="id01" name="" value="" label="" checked={isChecked03} onChange={() => setIsChecked03(!isChecked03)} disabled />
                <Checkbox id="id01" name="" value="" label="" checked={isChecked04} onChange={() => setIsChecked04(!isChecked04)} disabled />
            </Grid>
            <Grid >
                <DemoCode data={CheckboxConstants.basic} />
            </Grid>

            <Wrapper>
                <Grid>
                    <Typo size="h3">Checkbox Group Component Example</Typo>
                    <CheckboxGroup
                        options={options}
                        selectedValues={selectedValues}
                        onChange={handleCheckboxGroupChange}
                    />
                </Grid>
            </Wrapper>
        </Container>
    );
};

export default CheckboxGroupComp;