const AccordianConstant = {
    simple: [`
    <Accordion >
        <AccordionItem title="asdad" >
            <Typo>hi</Typo>
            <Typo>Accordion, AccordionItem, Button, Container, Grid, Typo</Typo>
            <Button caption="kdkadks" />
        </AccordionItem>
        <AccordionItem title="hfgh" >
            <p>hi</p>
        </AccordionItem>
    </Accordion>
`]

}

export default AccordianConstant;