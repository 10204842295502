import { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ImageComp from "./components/compImage/ImageComp";
import ButtonComp from "./components/compButton/ButtonComp";
import CardComp from "./components/compCard/CardComp";
import TableComp from "./components/compTable/TableComp";
import TextComp from "./components/compText/TextComp";
import UploadComp from "./components/compUpload/uploadComp";
import AccordianComp from "./components/compAccordian/AccordianComp";
import Home from "./components/home/home";
import GridComp from "./components/compGrid/GridComp";
import WrapperComp from "./components/compWrapper/WrapperComp";
import ConatinerComp from "./components/compContainer/ContainerComp";
import InputComp from "./components/compInput/InputComp";
import SearchComp from "./components/compSearch/SearchComp";
import Checkbox from "./components/compCheckbox/CheckboxComp";
import RangeDoubleSlider from "./components/rangeDoubleSelector/rangeDoubleSelectorComp";
import RangeSingleSelector from "./components/rangeSingleSelector/rangeSingleSelectorComp";
import Landingpage01 from "./components/landingPage/landingpage01";

const ProjectRoutes = () => {
    return (
        <Suspense fallback={<>Loading...</>}>
            <Router>
                <Routes>
                    <Route path="/" element={<Landingpage01/>} />
                    <Route path="/button" element={<ButtonComp />} />
                    <Route path="/image" element={<ImageComp />} />
                    <Route path="/card" element={<CardComp />} />
                    <Route path="/table" element={<TableComp />} />
                    <Route path="/typo" element={<TextComp />} />
                    <Route path="/upload" element={<UploadComp />} />
                    <Route path="/grid" element={<GridComp />} />
                    <Route path="/container" element={<ConatinerComp />} />
                    <Route path="/Wrapper" element={<WrapperComp />} />
                    <Route path="/input" element={<InputComp />} />
                    <Route path="/search" element={<SearchComp />} />
                    <Route path="/accordian" element={<AccordianComp/>} />
                    <Route path="/checkbox" element={<Checkbox/>} />
                    
                </Routes>
            </Router>
        </Suspense>
    );
}

export default ProjectRoutes;