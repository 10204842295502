const ContainerConstants = {

    nonContainer: [`
    <Grid>
        <Typo style={{ padding: 5 }}>Item 1</Typo>
        <Typo style={{ padding: 5 }}>Item 2</Typo>
        <Typo style={{ padding: 5 }}>Item 3</Typo>
        <Typo style={{ padding: 5 }}>Item 4</Typo>
        <Typo style={{ padding: 5 }}>Item 5</Typo>
    </Grid>
    `],

    constainer: [`
    <Grid container className="sample-background">
        <Typo style={{ padding: 20 }}>Item 1</Typo>
        <Typo style={{ padding: 20 }}>Item 2</Typo>
        <Typo style={{ padding: 20 }}>Item 3</Typo>
        <Typo style={{ padding: 20 }}>Item 4</Typo>
        <Typo style={{ padding: 20 }}>Item 5</Typo>
        <Typo style={{ padding: 20 }}>Item 6</Typo>
    </Grid>
`]
}

export default ContainerConstants;