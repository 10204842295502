import { FaCloudUploadAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Upload } from "woxin-user-interface";

const UploadComp = () => {
    return (
        <div className="container">
            <Upload multiple icon={<FaCloudUploadAlt />} caption="asdakdhak" closeIcon={<IoClose />} />
        </div>
    );
}

export default UploadComp;