import { Button, Table, Typo, Grid, Container, Wrapper } from "woxin-user-interface";
import BtnConstant from "./constant";
import DemoCode from "../../service/SampleCodeBox";

const ButtonComp = () => {

    const handleClick = () => {
        fetch('https://jsonplaceholder.typicode.com/todos/1')
            .then(response => response.json())
            .then(json => console.log(json))
    }

    return (
        <Container>
            <Wrapper>
                <Grid>
                    <Typo size="h3">Button</Typo>
                    <Typo>Buttons enable users to perform actions and make choices effortlessly, requiring just a single tap.</Typo>
                    <Typo>Buttons convey potential actions available to users and are strategically positioned across your UI, appearing in locations such as </Typo>
                    <Typo>Modal windows</Typo>
                    <Typo>Forms</Typo>
                    <Typo>Cards</Typo>
                    <Typo>Toolbars</Typo>
                </Grid>
            </Wrapper>
            <Wrapper>
                <Grid>
                    <Typo size="h5">Basic Button</Typo>
                </Grid>
                <Grid container className="sample-background">
                    <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
                        <Button caption='DEFAULT' />
                    </Grid>
                    <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
                        <Button caption='TEXT' variant="text" />
                    </Grid>
                    <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
                        <Button caption='FILL DARK' variant="fill-dark" />
                    </Grid>
                    <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
                        <Button caption='FILL LIGHT' variant="fill-light" />
                    </Grid>
                    <Grid xl={1} lg={2} md={3} sm={4} xs={6}>
                        <Button caption='STROKES' variant="storke" />
                    </Grid>
                </Grid>
                <Grid>
                    <DemoCode data={BtnConstant.variant} />
                </Grid>
            </Wrapper>
            <Wrapper>
                <Grid>
                    <Typo size="h5">Basic Width</Typo>
                </Grid>

                <Grid className="sample-background">
                    <Button caption='Default' />
                    <Button caption='Small' width="small" />
                    <Button caption='Medium' width="medium" />
                    <Button caption='Large' width="large" />
                    <Button caption='Full Width' width="fullWidth" />
                </Grid>
                <Grid>
                    <DemoCode data={BtnConstant.size} />
                </Grid>
            </Wrapper>
            <Wrapper>
                <Grid>
                    <Typo size="h5">Props</Typo>
                </Grid>

                <Table tableData={BtnConstant.tableData} />
            </Wrapper>

            <Wrapper>
                <Grid>
                    <Button onClick={handleClick} />
                </Grid>
            </Wrapper>
        </Container>
    );
}

export default ButtonComp;