import ComponentMaster from './components/ComponentManager';
import { Grid } from 'woxin-user-interface';

function App() {
  return (
    <Grid>
      <ComponentMaster />
    </Grid>
  );
}

export default App;
