import React from "react";
import './style.css';
import { Accordion, AccordionItem, Button, Container, Grid, Typo, Wrapper } from "woxin-user-interface";
import DemoCode from "../../service/SampleCodeBox";
import AccordianConstant from "./constant";

const AccordianComp = () => {
    return (
        <Container>
            <Grid container>

                <Wrapper>
                    <Grid>
                        <Typo size="h3">Accordian</Typo>
                        <Typo>
                            An accordion in HTML is an in a vertical course stacked overview of natural headings that can be tapped on or set off by a control center joint effort to reveal or cover related content. Accordions are a large part of the time used to lessen the prerequisite for investigating while at the same time showing various fragments of content on a single page.
                        </Typo>
                    </Grid>
                </Wrapper>

                <Wrapper>
                    <Typo size="h3">How it works?</Typo>
                    <Typo size='h5'>Overview</Typo>
                    <Typo>The Accordion and AccordionItem components are designed to create a collapsible section interface. The Accordion component serves as a container for AccordionItem components, managing their open and close states. Each AccordionItem can display a title, an optional icon, and its children content when active.</Typo>
                </Wrapper>

                <Wrapper>
                    <Grid container className="sample-background">
                        <Grid lg={6}>
                            <Accordion >
                                <AccordionItem title="asdad" >
                                    <Typo>hi</Typo>
                                    <Typo>Accordion, AccordionItem, Button, Container, Grid, Typo</Typo>
                                    <Button caption="kdkadks" />
                                </AccordionItem>
                                <AccordionItem title="hfgh" >
                                    <p>hi</p>
                                </AccordionItem>
                            </Accordion>
                        </Grid>
                    </Grid>
                    <Grid >
                        <DemoCode data={AccordianConstant.simple} />
                    </Grid>
                </Wrapper>
            </Grid>
        </Container>
    );
}

export default AccordianComp;