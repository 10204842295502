import { Container, Grid, Typo, Wrapper } from "woxin-user-interface";
import DemoCode from "../../service/SampleCodeBox";
import WrapperConstants from './constants';

const WrapperComp = () => {
    return (
        <Container>

            <Wrapper>
                <Typo size="h3">Wrapper</Typo>
                <Typo>
                    In the realm of web development, the layout is king, and CSS Grid Container emerges as a potent ruler, offering unparalleled control and flexibility in organizing web content. Revolutionizing the way web designers conceptualize and implement layouts, CSS Grid Container empowers creators to craft intricate designs with ease. Let's embark on a journey to explore the depths of this remarkable tool and uncover its transformative capabilities.
                </Typo>
            </Wrapper>

            <Wrapper>
                <Typo size='h4'>What are Containers?</Typo>
                <Typo>
                    Grid Container is a two-dimensional grid-based layout system that enables precise positioning and alignment of elements within a webpage. Unlike its predecessors, such as floats and positioning, CSS Grid Container introduces a native grid structure, streamlining the layout creation process and eliminating the need for intricate workarounds.
                </Typo>
            </Wrapper>

            <Wrapper>
                <Typo size='h4'>Without container</Typo>
                <Typo>Objects aligned to a grid will adopt entire width, seperately.</Typo>
            </Wrapper>

            <Wrapper>
                <Grid className="sample-background">
                    <Typo style={{ padding: 5 }}>Item 1</Typo>
                    <Typo style={{ padding: 5 }}>Item 2</Typo>
                    <Typo style={{ padding: 5 }}>Item 3</Typo>
                    <Typo style={{ padding: 5 }}>Item 4</Typo>
                    <Typo style={{ padding: 5 }}>Item 5</Typo>
                </Grid>
                <DemoCode data={WrapperConstants.nonContainer} />
            </Wrapper>

            <Wrapper>
                <Typo size='h4'>Container</Typo>
                <Typo>Container allows objects to share screen width together.</Typo>
            </Wrapper>

            <Wrapper>
                <Grid container className="sample-background">
                    <Typo style={{ padding: 20 }}>Item 1</Typo>
                    <Typo style={{ padding: 20 }}>Item 2</Typo>
                    <Typo style={{ padding: 20 }}>Item 3</Typo>
                    <Typo style={{ padding: 20 }}>Item 4</Typo>
                    <Typo style={{ padding: 20 }}>Item 5</Typo>
                    <Typo style={{ padding: 20 }}>Item 6</Typo>
                </Grid>
                <DemoCode data={WrapperConstants.constainer} />
            </Wrapper>
        </Container>
    );
}

export default WrapperComp;