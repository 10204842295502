import { Card, Table } from "woxin-user-interface";
import { FaCloudUploadAlt } from "react-icons/fa";
import CardConstant from "./constant";
import DemoCode from "../../service/SampleCodeBox";

const CardComp = () => {
    return (
        <div className="container">

            <section>
                <h1>Card</h1>
                <p>Card encapsulates information and actions</p>
                <p>These surfaces are designed to present content and actions on a singular subject, ensuring ease of scanning for pertinent and actionable details. It can arrange elements such as </p>
                <ul>
                    <li>Text</li>
                    <li>Icon</li>
                    <li>Image</li>
                </ul>
                <p>on the cards in a manner that distinctly conveys hierarchy.</p>
            </section>

            <section>
                <h2>Basic Card</h2>
                <div className="sample-background display-flex">
                    <div className="display-flex">
                        <Card cardGutter={10} elements={["iconRight", "typo"]} cardBackground="#1E88E5" cardColor="white" iconName={<FaCloudUploadAlt />} widthCard={20} typoContent="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                        <Card cardGutter={10} elements={["iconLeft", "typo"]} cardBackground="white" cardBorder={1} cardBorderStyle="dashed" cardColor="#1E88E5" iconName={<FaCloudUploadAlt />} widthCard={20} typoContent="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                    </div>
                </div>
                <div>
                    <DemoCode data={CardConstant.basic} />
                </div>
            </section>

            <section>
                <h2>Card Elements</h2>
                <div className="sample-background display-flex">
                    <div className="display-flex">
                        <Card cardGutter={10} elements={["iconLeft"]} iconName={<FaCloudUploadAlt />} />
                        <Card cardGutter={10} elements={["snapLeft"]} widthCard={10} snapLink="http://tinyurl.com/2ya359cp" />
                        <Card cardGutter={10} elements={["typo"]} typoContent="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                    </div>
                </div>
                <div>
                    <DemoCode data={CardConstant.elements} />
                </div>
            </section>

            <section>
                <h2>Alignment</h2>
                <div className="sample-background">
                    <div className="display-flex">
                        <Card cardGutter={10} elements={["iconLeft", "typo"]} widthCard={20} iconName={<FaCloudUploadAlt />} typoContent="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                        <Card cardGutter={10} elements={["iconRight", "typo"]} widthCard={20} iconName={<FaCloudUploadAlt />} typoContent="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                    </div>
                    <div className="display-flex mt-10">
                        <Card cardGutter={10} elements={["snapRight", "typo"]} widthSnap={20} widthCard={20} snapLink="http://tinyurl.com/2ya359cp" typoContent="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                        <Card cardGutter={10} elements={["snapLeft", "typo"]} widthSnap={20} widthCard={20} snapLink="http://tinyurl.com/2ya359cp" typoContent="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                    </div>
                    <div className="display-flex mt-10">
                        <Card cardGutter={10} elements={["snapRight", "iconLeft"]} widthSnap={60} widthCard={10} iconName={<FaCloudUploadAlt />} snapLink="http://tinyurl.com/2ya359cp" />
                        <Card cardGutter={10} elements={["snapRight", "iconRight"]} widthSnap={60} widthCard={10} iconName={<FaCloudUploadAlt />} snapLink="http://tinyurl.com/2ya359cp" />
                    </div>
                </div>
                <div>
                    <DemoCode data={CardConstant.alignment} />
                </div>
            </section>

            <section>
                <h1>Props</h1>
                <Table tableData={CardConstant.tableData} />
            </section>

        </div>
    );
}

export default CardComp;